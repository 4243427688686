import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         staleTime: 0,
         refetchOnWindowFocus: false,
         refetchInterval: 1000 * 60 * 5, // 5 minutes,
         retry: false,
      },
   },
});
